import Api from "../../modules/Core/Helpers/Api";
import Date from "../../modules/Core/Helpers/DateTime";
import Value from "../../modules/Core/Helpers/Value";

const contentApi = Api.create("content");
const shareApi = Api.create("share");

function prepareOptions(options) {
  options = options || {};
  const { params = {} } = options;
  params.date = Date.getCurrentDate();
  options.params = params;
  return options;
}

function addParam(options, param, value) {
  options = options || {};
  const { params = {} } = options;
  params[param] = value;
  options.params = params;

  return options;
}

const ExportDefault = {
  contact: function (account, repository, options) {
    return contentApi.post(
      `account/${account}/repository/${repository}/email/contact`,
      options
    );
  },
  /**
   *
   * Shared
   *
   */
  languages: function (account, app, options) {
    options = prepareOptions(options);

    return shareApi.get(`language/account/${account}/app/${app}`, options);
  },
  categories: function (account, repository, options) {
    options = prepareOptions(options);

    return shareApi.get(
      `content/account/${account}/repository/${repository}/categories`,
      options
    );
  },
  category: {
    items: function (slug, account, repository, options) {
      options = prepareOptions(options);

      return shareApi.get(
        `content/account/${account}/repository/${repository}/category/${slug}/items`,
        options
      );
    },
  },
  item: function (slug, account, repository, options) {
    options = prepareOptions(options);

    return shareApi.get(
      `content/account/${account}/repository/${repository}/item/${slug}`,
      options
    );
  },
  search: function (term, account, repository, options) {
    options = prepareOptions(options);
    options.params.term = term;

    return shareApi.get(
      `casadipatsi/account/${account}/repository/${repository}/stock/search/product`,
      options
    );
  },
  /**
   *
   * Hook
   *
   */
  hook: function (account, repository, hook, version, options) {
    return shareApi.get(
      `content/account/${account}/repository/${repository}/hook/${hook}/${version}`,
      options
    );
  },
  hookPost: function (account, repository, hook, version, data, options) {
    options = addParam(options, "lang", Value.getValue("lang"));

    return contentApi.post(
      `account/${account}/repository/${repository}/hook/${hook}/${version}`,
      data,
      options
    );
  },
  hookAuth: function (account, repository, hook, version, options) {
    options = addParam(options, "lang", Value.getValue("lang"));

    return contentApi.get(
      `account/${account}/repository/${repository}/hook/${hook}/${version}/auth`,
      options
    );
  },
  hookPostAuth: function (account, repository, hook, version, data, options) {
    options = addParam(options, "lang", Value.getValue("lang"));

    return contentApi.post(
      `account/${account}/repository/${repository}/hook/${hook}/${version}/auth`,
      data,
      options
    );
  },
};

export default ExportDefault;
